import React from "react"
import { graphql, navigate } from "gatsby"
import Pagination from "react-js-pagination"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import ContactForm from "../components/contactForm"
import PostSingle from "../components/postSingle"
import Sidebar from "../components/sidebar"
import { createCategorySlug } from "../utils"
import { buildCategoryUrl } from "../utils/urls"

/**
 * GraphQL Query to retrieve template data
 * The query params are part of the component pageContext,
 * this context is coming from gatsby-node.js file
 *
 * @param {String} categoryId
 * @param {Int} skip
 * @param {Int} limit
 * @return {Object}
 */
export const categoryTemplateQuery = graphql`
  query($categoryId: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/hero.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    category: sanityCategory(id: { eq: $categoryId }) {
      id
      title
      description
    }
    posts: allSanityPost(
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
      skip: $skip
      limit: $limit
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
          mainImage {
            asset {
              _id
              url
              fluid(maxHeight: 360) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          categories {
            title
          }
          author {
            name
          }
          excerpt
        }
      }
    }
  }
`

/**
 * CategoryTemplate Component
 * This component is used to generate the pages related to categories pages.
 *
 * @param {Object} props
 */
const CategoryTemplates = ({ data, pageContext }) => {
  const category = data.category
  const categorySlug = createCategorySlug(
    category.title
      .replace(/[^A-Z0-9]+/gi, "-")
      .trim()
      .toLowerCase()
  )
  const posts = data.posts.edges.map(({ node }) => node)

  /**
   * Handle page change function
   * This function execute the `navigate` gatsby function
   * that fires a route change without reload the page
   *
   * @param {Int} pageNumber
   */
  const handlePageChange = pageNumber => {
    const url =
      pageNumber === 0 || pageNumber === 1
        ? buildCategoryUrl(categorySlug)
        : buildCategoryUrl(categorySlug, true, pageNumber)

    navigate(url)
  }

  return (
    <Layout isSecondary={true}>
      <SEO title={`${category.title} | News`} description="Latest news" />

      <Hero
        type="simple"
        tagline="Most successful Law Firm"
        title={`${category.title} News`}
        background={data.hero.sharp.fluid}
      />

      <section className="news">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-9">
              <div className="news__content">
                {posts.map(post => (
                  <PostSingle key={post.id} post={post} />
                ))}

                {pageContext.numberOfPages > 1 && (
                  <Pagination
                    innerClass="pagination"
                    linkClass="pagination__link"
                    activeLinkClass="active"
                    linkClassPrev="pagination__prev"
                    linkClassNext="pagination__next"
                    hideFirstLastPages={true}
                    prevPageText="Prev"
                    nextPageText="Next"
                    activePage={pageContext.humanPageNumber}
                    itemsCountPerPage={2}
                    totalItemsCount={pageContext.totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </div>

            <div className="col-lg-3">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default CategoryTemplates
